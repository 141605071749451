import styled from '@emotion/styled'
import React from 'react'
import BreakPoints from '../../styles/breakPoints'
import ColumnH1 from '../atoms/columnH1'
import Introduction004 from './Introduction004'
import Introduction005 from './Introduction005'
import Introduction006 from './Introduction006'
import Introduction007 from './Introduction007'
import Introduction008 from './Introduction008'
import Introduction009 from './Introduction009'
import Introduction010 from './Introduction010'
import Introduction011 from './Introduction011'
import Introduction012 from './Introduction012'

const Wrapper = styled.article`
  position: relative;
  margin-bottom: 30px;
  .p-txt-date {
    margin-top: -10px;
    color: #ababab;
  }
  section {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    font-size: 16px;
    color: #505050;
    line-height: 1.6em;
    h1 {
      color: #414141;
      font-size: 22px;
      line-height: 1.4em;
      background: #f5f5f5;
      border-radius: 3px;
      position: relative;
      width: 100%;
      padding: 5px 5px 5px 20px;
      margin-bottom: 30px;
      margin-top: 10px;
      &:before {
        content: '';
        position: absolute;
        width: 10px;
        background: #77d7c9;
        left: 0;
        top: 0;
        border-radius: 3px 0 0 3px;
        height: 100%;
      }
    }
    h2 {
      color: #414141;
      font-size: 22px;
      line-height: 1.4em;
      background: #ffffff;
      border-radius: 3px;
      position: relative;
      width: 100%;
      padding: 5px 5px 5px 20px;
      margin-top: 10px;
      margin-bottom: 30px;
      &:before {
        content: '';
        position: absolute;
        width: 10px;
        background: #d8d8d8;
        left: 0;
        top: 0;
        border-radius: 3px 0 0 3px;
        height: 100%;
      }
    }
    h3 {
      color: #414141;
      font-size: 16px;
      line-height: 1.4em;
      background: #ffffff;
      width: auto;
      padding: 10px 0 8px;
      margin-top: -10px;
      margin-bottom: 20px;
      border-top: solid 2px #e5e5e5;
      border-bottom: solid 2px #e5e5e5;
    }
    p {
      padding-bottom: 30px;
      line-height: 2;
      position: relative;
      span {
        background: linear-gradient(transparent 60%, #ff6 60%);
        font-weight: 600;
      }
      .p-list-link {
        position: absolute;
        top: 6px;
        right: 2px;
        &:before {
          display: block;
          content: '';
          position: absolute;
          width: 0;
          height: 0;
          top: 7px;
          left: -14px;
          border-left: 8px solid #3ec7b3;
          border-top: 4px solid transparent;
          border-bottom: 4px solid transparent;
        }
      }
      &.txt-color-red {
        color: #ff6969;
      }
    }
    .link-div {
      width: 100%;
      display: flex;
      justify-content: left;
      padding-bottom: 30px;
      > div {
        width: 50px;
      }
      .link-p {
        width: calc(100% - 50px);
        .p-list-link {
          position: absolute;
          top: -3px;
          left: 30px;
          &:before {
            display: block;
            content: '';
            position: absolute;
            width: 0;
            height: 0;
            top: 11px;
            left: -14px;
            border-left: 8px solid #3ec7b3;
            border-top: 4px solid transparent;
            border-bottom: 4px solid transparent;
          }
        }
      }
    }
    img {
      width: 100%;
      height: auto;
      padding-bottom: 30px;
    }
  }
  ${BreakPoints.large} {
  }
`
type Props = {
  /** 広告の番号 */
  no: number
}

/**
 * コラム記事詳細：記事本体
 * @constructor
 */
const ColumnDetailInner0063 = (props: Props): JSX.Element => (
  <Wrapper>
    <ColumnH1 label="勤怠管理と給与計算の連携について。なぜ連携した方がいいの？留意すべきポイントとは" />
    {/** TODO 川上申し送り事項：▼投稿日をここに表示 */}
    <p className="p-txt-date">2024.06.21</p>
    <section>
      {/** TODO 川上申し送り事項：▼記事投稿（？）で作成された内容がここに入る予定 */}
      <p>
        一定以上の規模になると勤怠管理と給与計算は密接に関連することとなります。もちろん連携しない状態であっても運用自体は可能ですが、相互に連携することで相互の事務の簡素化合理化を進めることができます。今回は勤怠管理と給与計算の連携にフォーカスをあて解説します。{' '}
      </p>
      <img src="/images/column/details/c0063.jpg" alt="キンクラコラム画像63" />
      <h1 id="toc1">勤怠管理とは</h1>
      <p>
        勤怠管理とは出退勤時刻や年次有給休暇の取得等、日々の勤怠記録のことを指します。現在、労働安全衛生法によって、企業には労働時間の客観的な把握義務が課せられており、ITツール等を活用した勤怠管理ソフトを導入することで、担当者の手間を減らしながらも集計作業が簡便になる等多くのメリットが多くあります。
      </p>

      <h1 id="toc2">給与計算とは</h1>
      <p>
        言うまでもなく従業員等へ支給する給与を計算することですが、給与には大きく分けて3つの項目あります。
        <span>1つは勤怠項目、2つ目は支給項目、3つ目に控除項目</span>
        があります。給与は何らかの根拠情報に基づいて支給する（または控除する）こととなり、その根拠となるものが勤怠情報となります。
      </p>

      {/** ここに9パターンの広告設定する */}
      {props.no === 0 && <Introduction004 />}
      {props.no === 1 && <Introduction005 />}
      {props.no === 2 && <Introduction006 />}
      {props.no === 3 && <Introduction007 />}
      {props.no === 4 && <Introduction008 />}
      {props.no === 5 && <Introduction009 />}
      {props.no === 6 && <Introduction010 />}
      {props.no === 7 && <Introduction011 />}
      {props.no === 8 && <Introduction012 />}

      <h1 id="toc3">なぜ相互に連携がかかった方がよいのか？</h1>
      <p>
        従業員数が一定数を超えると様々な職種や勤務形態、個々の事情を抱えた従業員の存在が色濃くなります。例えば夜間勤務がある職種、変形労働時間制を採用する事業形態、介護や育児と並行しながら労務の提供をする労働者（突発的な休暇が多いことが想定される）に対して、
        <span>アナログ管理ではいずれ限界が訪れます。</span>
        例えば紙台帳で管理するとなれば必要な情報に瞬時にアクセスすることが難しく、過去の情報を検索する際にも多くの時間を要します。特に給与計算には「給与支給日」という絶対的に遅れることができない締め切り日が存在します。また、
        <span>
          給与計算は勤怠情報を基に計算をするという性質上、連携がかかることで自動的に計算が可能（中には手作業が入る部分もある）となり、余裕を持った作業が可能となります。
        </span>
        どのような業務であっても最後は人間が作業する部分があり、焦りによってミスが発生しやすくなるということは容易に想像がつきます。そこで、機械的な作業で凌げる部分は機械に委ね、人為的なミスを回避するためにも、本来人の目でチェックすべき部分に時間をより投入できるようにすることが適切です。保険料率の改定なども法令に則った情報をキャッチアップしていくのは一定の時間と労力を要しますが、現在のシステムでは法改正ごとに常にアップデートが行われています。
      </p>

      <h1 id="toc4">連携時に留意すべきポイントとは？</h1>
      <p>
        自動計算が可能と言っても課題がないわけではありません。そもそも初期設定が誤っていた場合、その時点で正しい答えが算出できないということです。また、企業内のルール変更があった場合にはその変更内容に応じて設定を変えなければ誤った計算にもなりかねません。よって、多くの場合、当初の
        <span>初期設定にはある程度時間と労力の投入が必要</span>です。
        <br />
        <br />
        また、<span>データのバックアップも忘れずに行っておくべき</span>
        です。ただし、現在はクラウド型等を始め、自動的にバックアップおよびアップデートを行ってくれるシステムも多く、より業務の効率化が実現しています。
        <br />
        <br />
        次に
        <span>
          全ての勤怠情報が揃った状態でなければ連携できないという場合は作業に時間を要してしまいます
        </span>
        （逆に紙台帳やExcelでの管理であればこのような問題はおきません）。特に部門数が多い企業では勤怠情報の確認に時間差が出てしまい、全部門が揃わないと言った事情もあります。ただし、勤怠情報の確認が終了している部門から順次連携がかけることができるシステムであればこの問題はそこまで大きな問題ではありません。
      </p>

      <h1 id="toc5">勤怠システムと給与計算事務の連携による法違反回避事例</h1>
      <p>
        現在は（現時点で一部の業種で猶予中の業種も有り）企業に対して時間外労働の上限規制が課せられており、旧来は「青天井」であった時間労働時間数も法律によって上限が設定されました。また、2023年4月からは中小企業に対しても月60時間を超える時間外労働に対しては割増賃金率が25％から50％に引き上げられます。これは給与計算事務の観点でも重要な論点ですが、その前に
        <span>
          勤怠システムでアラート機能等が発動すれば月60時間を超えるような状態になる前に早期に注意喚起することができます。
        </span>
        もちろん、超えた場合には当該情報が給与計算システムに連動することで誤った割増賃金率が適用されることもありませんので、相互にメリットがあると言えます。特に恒常的な時間外労働が起こっている部門については多忙ゆえに人的な注意喚起機能が働かないことが想定されるため、機械的にチェックをかけることで法違反を回避できるよう検討している企業も少なくありません。
      </p>

      <h1 id="toc6">最後に</h1>
      <p>
        勤怠管理と給与計算を連携させることで人為的なミスの回避と事務の簡素化合理化が促進されます。もちろん別々に管理することで情報紛失のリスクを減らせるという声もありますが、実際にそのようなことが起こる可能性はそこまで大きくはなく、それよりも毎月必ず発生する給与計算事務の簡素化合理化に目を向ける方が肝要と言えます。
      </p>
    </section>
  </Wrapper>
)

export default ColumnDetailInner0063
